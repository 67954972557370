// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const staffConfig = [
  {
    title: '申购',
    path: '/admin/neworder',
    icon: icon('ic_analytics'),
  },
  {
    title: '退购',
    path: '/admin/returnorder',
    icon: icon('ic_analytics'),
  },
];

export default staffConfig;
