import * as React from 'react';
import { useState, useEffect } from 'react'; // useRef, useCallback
import { useParams,useNavigate,useMatch   } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import UsbIcon from '@mui/icons-material/Usb';
// eslint-disable-next-line import/no-unresolved
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';

function PinCellRenderer(props) {
  const { value } = props;

  return (
    <Grid container alignItems="right">
      {value ? (
      <Grid item>
        <IconButton size="small">
          <UsbIcon />
        </IconButton>
      </Grid> ) : null}
    </Grid>
  );
}

const columns = [
  { field: 'id', headerName: 'ID', width: 150, hide: true},
  {
    field: 'last',
    headerName: '最后上线',
    width: 110,
    editable: false,
    valueFormatter: (params) => { 
      const date = new Date(params.value || new Date());
      const minutes = (`0${  date.getMinutes()}`).slice(-2); 
      const hours = (`0${  date.getHours()}`).slice(-2);     
      return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
    }, 
  },
  {
    field: 'since',
    headerName: '启动时间',
    width: 105,
    editable: false,
    valueFormatter: (params) => { 
      const date = new Date(params.value || new Date());
      const minutes = (`0${  date.getMinutes()}`).slice(-2); 
      const hours = (`0${  date.getHours()}`).slice(-2);
      if(new Date(params.value).getTime()===0) return '未启动';
      return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
    },  
  },
  {
    field: 'mac',
    headerName: '机号',
    width: 80,
    editable: false,
    valueGetter: (params) => params.value?.slice(-6) || '', 
  },
  {
    field: 'sidDesc',
    headerName: '會員備註',
    width: 80,
    editable: false,
    valueGetter: (params) => params.value?.slice(-6) || '', 
  },
  {
    field: 'sid',
    headerName: '会员',
    width: 140,
    editable: false,
    valueGetter: (params) => params.value || '0',
    valueFormatter: (params) => {
      if (params.value.substr(0,2)==='86')  return params.value.substr(2);
      if (params.value.substr(0,3)==='886') return `0${params.value.substr(3)}`;
      return params.value;
    },
  },
  {
    field: 'status',
    headerName: '状态',
    width: 90,
    editable: false,
    valueGetter: (params) => params.value || '',
  },
  {
    field: 'avg',
    headerName: '日收',
    width: 80,
    editable: false,
    valueFormatter: (params) => `$ ${params.value.toFixed(2)}`,
    valueGetter: (params) => params.value || 0,
  },
  {
    field: 'renvue',
    headerName: '總收益',
    width: 100,
    editable: false,
    valueFormatter: (params) => params.value.toFixed(2),
    valueGetter: (params) => params.value || 0,
  },
  {
    field: 'vm',
    headerName: '虚拟机',
    width: 70,
    editable: false,
    valueFormatter: (params) => params.value ? '🤖' : '',
    valueGetter: (params) => Boolean(params.value),
  },
  {
    field: 'pin',
    headerName: 'Pin',
    width: 50,
    editable: false,
    valueFormatter: (params) => params.value ? 'Y' : '',
    valueGetter: (params) => params.value || false,
    renderCell: PinCellRenderer,
  },
  {
    field: 'pinGroup',
    headerName: 'Pin组',
    width: 140,
    editable: false,
  },
  {
    field: 'pinId',
    headerName: 'Pin编号',
    width: 70,
    editable: false,
    valueGetter: (params) => params.value || '',
  },
  {
    field: 'ban',
    headerName: '黑名单',
    width: 55,
    editable: false,
    valueFormatter: (params) => params.value ? 'Y' : '',
    valueGetter: (params) => params.value || false,
  },
  {
    field: 'ratio',
    headerName: '倍率',
    width: 80,
    editable: true,  
    valueFormatter: (params) => `$ ${params.value.toFixed(2)}` ,
    valueGetter: (params) => params.value || 0,
  },
  {
    field: 'ip',
    headerName: 'IP',
    width: 130,
    editable: false,
    valueGetter: (params) => params.value || '0.0.0.0',
    valueFormatter: (params) => params.value==='172.021.000.006' ? "虛擬機" : params.value,

  },
  {
    field: 'oid',
    headerName: '订单号',
    width: 110,
    editable: false,
    valueGetter: (params) => params.value || '',
  },
];

const columnVisibilityModel = {
  id:false
}

export default function Mac() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const { sid } = useParams();
  const { ip } = useParams();
  const navigate = useNavigate();
  const match = useMatch('admin/mac/ban');
  const [rows, setRows] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("mac"); 
  const [filterValue, setFilterValue] = useState(""); 


  const fetchData = async () => {
    try {
      const response = await axios.get('https://dash.surfshark-router.com/api/mac', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item._id };
  
          delete modifiedItem._id;
  
          return modifiedItem;
        });
  
        setRows(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  
  const fetchDataBySid = async (sid) => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/mac', { sid }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item._id };
          delete modifiedItem._id;
          return modifiedItem;
        });
  
        setRows(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
          navigate('/login', { replace: true });
        } else {
          // Other 4xx or 5xx errors
          alert('發生錯誤');
        }
      } else {
        // Network error or other issues
        alert('發生網路錯誤或其他問題');
      }
    }
  };

  const fetchDataByBan = async () => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/mac', { ban:true }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item._id };
          delete modifiedItem._id;
          return modifiedItem;
        });
  
        setRows(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
          navigate('/login', { replace: true });
        } else {
          // Other 4xx or 5xx errors
          alert('發生錯誤');
        }
      } else {
        // Network error or other issues
        alert('發生網路錯誤或其他問題');
      }
    }
  };

  const fetchDataByIp = async (ip) => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/mac', { ip }, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item._id };
          delete modifiedItem._id;
          return modifiedItem;
        });
  
        setRows(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
          navigate('/login', { replace: true });
        } else {
          // Other 4xx or 5xx errors
          alert('發生錯誤');
        }
      } else {
        // Network error or other issues
        alert('發生網路錯誤或其他問題');
      }
    }
  };
  
  const fetchFilterData = async () => {
    try {
      let body;
      
      switch (selectedFilter) {
        case 'hadban':
          body = { ban: true };
          break;
        case 'notban':
          body = { ban: false };
          break;
        case 'isfake':
          body = { vm: true };
          break;
        case 'nofake':
          body = { vm: false };
          break;
        case 'hadpin':
          body = { pin: true };
          break;
        case 'notpin':
          body = { pin: false };
          break;
        default:
          body = { [selectedFilter]: filterValue };
      }
  
      const response = await axios.post('https://dash.surfshark-router.com/api/mac', body, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item._id };
          delete modifiedItem._id;
          return modifiedItem;
        });
  
        setRows(modifiedData);
      }
  
    } catch (error) {
      console.error('Error fetching data: ', error);
      
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
          navigate('/login', { replace: true });
        } else {
          // Other 4xx or 5xx errors
          alert('發生錯誤');
        }
      } else {
        // Network error or other issues
        alert('發生網路錯誤或其他問題');
      }
    }
  };

  const handleReset = () => {
      setFilterValue('');
      setSelectedFilter('');
      fetchData();
  };

  const handleCellClick = (params) => {

    const validFields = ['mac', 'sid', 'oid', 'pinId', 'status', 'pinGroup', 'ip', 'fake', 'ban', 'pin'];
  
    if (validFields.includes(params.field)) {
      setSelectedFilter(params.field);
  
      const clickedValue = params.row[params.field];
  
      if (['fake', 'ban', 'pin'].includes(params.field)) {
        setFilterValue(clickedValue === true ? 'Y' : '');
        switch (params.field) {
          case 'fake':
            if(clickedValue) {
              setSelectedFilter('isfake')
            }else {
              setSelectedFilter('nofake')
            }
            break;
          case 'ban':
            if(clickedValue) {
              setSelectedFilter('hadban')
            }else {
              setSelectedFilter('notban')
            }
            break;
          case 'pin':
            if(clickedValue) {
              setSelectedFilter('hadpin')
            }else {
              setSelectedFilter('notpin')
            }
            break;
          default:
            break;
        }
      } else if (params.field === 'mac') {
        setFilterValue(clickedValue.slice(-6));
      } else {
        setFilterValue(clickedValue);
      }
    }
  };
  
  useEffect(() => {
    if (sid) {
      fetchDataBySid(sid);
      setFilterValue(sid);
      setSelectedFilter('sid')
    }else if (ip) {
      fetchDataByIp(ip);
      setFilterValue(ip);
      setSelectedFilter('ip')
    }else if(match) {
      fetchDataByBan();
      setFilterValue('Y');
      setSelectedFilter('hadban')
    }
    else {
      fetchData();
    }
  }, [sid, ip]); 

  useEffect(() => {
    switch (selectedFilter) {
      case 'hadban':
        setFilterValue('Y');
        break;
      case 'notban':
        setFilterValue('');
        break;
      case 'isfake':
        setFilterValue('Y');
        break;
      case 'nofake':
        setFilterValue('');
        break;
      case 'hadpin':
        setFilterValue('Y');
        break;
      case 'notpin':
        setFilterValue('');
        break;
      default:
        break;
    }
  }, [selectedFilter]);
  
  return (
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                机器 
                </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">过滤搜寻</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                    <FormControlLabel value="mac" control={<Radio />} label="机号" />
                    <FormControlLabel value="ip" control={<Radio />} label="ip" />
                    <FormControlLabel value="sid" control={<Radio />} label="会员" />
                    <FormControlLabel value="oid" control={<Radio />} label="订单号" />
                    <FormControlLabel value="pinId" control={<Radio />} label="Pin编号" />
                    <FormControlLabel value="status" control={<Radio />} label="状态" />
                    <FormControlLabel value="pinGroup" control={<Radio />} label="Pin群组" />
                    <FormControlLabel value="hadban" control={<Radio />} label="黑名单" />
                    <FormControlLabel value="notban" control={<Radio />} label="无黑名单" />
                    <FormControlLabel value="hadpin" control={<Radio />} label="有Pin" />
                    <FormControlLabel value="notpin" control={<Radio />} label="无Pin" />      
                    <FormControlLabel value="isfake" control={<Radio />} label="虚拟机" />  
                    <FormControlLabel value="nofake" control={<Radio />} label="实机" />              
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  style={{ flex: 1,width:'50%' }}  // Let TextField take up as much space as possible
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={fetchFilterData}
                  style={{ marginLeft: '10px' }}  // Optional: Add some spacing between TextField and Button
                >                 
                    搜索
                </Button>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}  // Optional: Add some spacing between TextField and Button
                >
                    清除条件
                </Button>
            </Grid>
      </Grid>
      <DataGridPro
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'last', sort: 'desc' }],
          },
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        disableColumnMenu
        onCellClick={handleCellClick}
      />
    </Box>
  );
}