import * as React from 'react';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const dispatchModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};



export default function ReturnOrders() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [reason,setReason]= useState('');
  const [editedRows, setEditedRows] = React.useState([]);
  const [dispatchData, setDispatchData] = useState({
    id: '',
    sid: '',
    brief: '',
    date: '',
    ip: '',
    data: {
      units: [],
      contactName: '',
      contactPhone: '',
      contactCountry: '',
      contactZipCode: '',
      contactAddress: '',
      contactTime: '',
    },
  });
  const [shippingId, setShippingId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const handleClickDialogOpen = (id) => {
    setOrderId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'OrderNo', width: 80 },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span>{params.value}</span>
          {params.value === 'processing' ? (
            <ButtonGroup variant="text" aria-label="outlined primary button group">
              <Button onClick={() => handleDispatch(params.row)}>dispatch</Button>
              <Button onClick={() => handleClickDialogOpen(params.row.id)}>cancel</Button>
            </ButtonGroup>
          ) : params.value === 'shipping' ? (
            <ButtonGroup variant="text" aria-label="outlined primary button group">
              <Button onClick={() => handleComplete(params.row)}>complete</Button>
              <Button onClick={() => handleClickDialogOpen(params.row.id)}>cancel</Button>
            </ButtonGroup>
          ) : null}
        </div>
      ),     
    },
    { field: 'sid', headerName: 'Member', width: 150 },
    { field: 'desc', headerName: 'Mark', width: 160, editable:true},
    { field: 'shippingId', headerName: 'ShippingId', width: 100 },
    { field: 'brief', headerName: 'Describe', width: 200 },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
        const formattedDate = date.getDate().toString().padStart(2, '0');
        const formattedHours = date.getHours().toString().padStart(2, '0');
        const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
    
        return `${formattedMonth}/${formattedDate} ${formattedHours}:${formattedMinutes}`;
      },
    },
    { field: 'ip', headerName: 'IP', width: 150 },
    {
      field: 'unitsCount',
      headerName: 'Amount',
      width: 80,
      renderCell: (params) => (
        <Button onClick={() => handleShowUnits(params.row.data.units)}>
          {params.row.data.units.length}
        </Button>
      ),
    },
    { 
      field: 'contactName', 
      headerName: 'Sender', 
      width: 80, 
      valueGetter: (params) => params.row.data.contactName
    },
    { 
      field: 'contactPhone', 
      headerName: 'ContactNumber', 
      width: 150, 
      valueGetter: (params) => params.row.data.contactPhone
    },
    { 
      field: 'contactCountry', 
      headerName: 'Country', 
      width: 150, 
      valueGetter: (params) => params.row.data.contactCountry
    },
    { 
      field: 'contactZipCode', 
      headerName: 'ZipCode', 
      width: 80, 
      valueGetter: (params) => params.row.data.contactZipCode
    },
    { 
      field: 'contactAddress', 
      headerName: 'Address', 
      width: 250, 
      valueGetter: (params) => params.row.data.contactAddress
    },
    { 
      field: 'contactTime', 
      headerName: 'Time Period', 
      width: 150, 
      valueGetter: (params) => params.row.data.contactTime
    },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dash.surfshark-router.com/api/orders/return', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        setRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('error');
      }
    }
  };
  
  const handleDispatch = (row) => {
    console.log('派件', row);
    setDispatchData(row);
    setModalOpen(true);
  };
  const handleCloseDispatchModal = () => {
    setModalOpen(false);
  };
  const handlePostShippingId = async () => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/order', {
        id: dispatchData.id,
        shippingId,
        status: "shipping",
      });
      console.log(response.data);
      alert('success');
      fetchData();
      handleCloseDispatchModal();
    } catch (error) {
      console.error(error);
      alert('error');
    }
  };

  const handlePostReason = async () => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/order', {
        id: orderId,
        desc:reason,
        status: "canceled"
      });
      alert('success');
      fetchData();
      handleCloseDispatchModal();
      handleDialogClose();
    } catch (error) {
      console.error(error);
      alert('error');
    }
  };
  const processRowUpdate = (newRow, oldRow) => {
    // 透過 newRow 的 id 找到 editedRows 陣列中的索引
    const index = editedRows.findIndex(row => row.id === newRow.id);
  
    // 若找到相同的 id，則先刪除
    if (index > -1) {
      editedRows.splice(index, 1);
    }
  
    // 將 newRow 加入 editedRows 陣列
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };


  const  handleComplete = async (row) => {
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/order', {
        id: row.id,
        desc:reason,
        status: "completed"
      });
      alert('success');
      fetchData();
      handleCloseDispatchModal();
      handleDialogClose();
    } catch (error) {
      console.error(error);
      alert('error');
    }
  };

  const handleSave = async () => {
    const modifiedRows = editedRows.map(item => ({
      ...item,
    }));
  
    const requests = modifiedRows.map(async (row) => {
      try {
        const response = await axios.post('https://dash.surfshark-router.com/api/order', {
          id: row.id,
          desc: row.desc
        });
        console.log('提交成功', response.data);
      } catch (error) {
        console.error('提交失败', error);
        alert('error');
      }
    });
    await Promise.all(requests);
    fetchData();
    setEditedRows([]);
    alert('success');
  };

  const handleShowUnits = (units) => {
    setSelectedUnits(units);
    handleOpen();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRestore = async () => {
    setEditedRows([]);
    fetchData();
  };
  useEffect(() => {
    fetchData()
  }, []); 


  const isDisabled = editedRows.length === 0;
  return (
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                Refund 
                </Typography>
            </Grid>
      </Grid>
      <Button variant="outlined" disabled={isDisabled} onClick={handleSave}  startIcon={<SaveIcon />}> 
      Save
      </Button>
      <Button variant="outlined" disabled={isDisabled} onClick={handleRestore}  startIcon={<ClearIcon />}> 
      Reduction
      </Button>
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        disableColumnMenu
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error=>alert(error)}
        onRowEditCommit={(rowId, e) => {
          handleSave(rowId,e);
        }}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Units
            </Typography>
            <Typography id="transition-modal-description" variant="h5" sx={{ mt: 2,mb:2 }}>
              <ul>
                {selectedUnits.map((unit, index) => (
                  <li key={index}>{unit}</li>
                ))}
              </ul>
            </Typography>
            <Button onClick={handleClose}>Close</Button>
          </Box>
        </Fade>
      </Modal>
      <Modal
  open={modalOpen}
  onClose={handleCloseDispatchModal}
  aria-labelledby="dispatch-modal-title"
  aria-describedby="dispatch-modal-description"
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
>
  <Fade in={modalOpen}>
    <Box sx={dispatchModalStyle}>
      <Typography id="dispatch-modal-title" variant="h6" component="h2">
        派件详情
      </Typography>
      
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <Typography id="dispatch-modal-description-left" variant="h5" component="div">
            <p>OrderNo: {dispatchData.id}</p>
            <p>MemberNo: {dispatchData.sid}</p>
            <p>Name: </p>
            <p>Description: {dispatchData.brief}</p>
            <p>Date: {new Date(dispatchData.date).toLocaleString()}</p>
            <p>IP: {dispatchData.ip}</p>
            <p>Amount: {dispatchData.data.units.length}</p>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="dispatch-modal-description-right" variant="h5" component="div">
            <p>Sender: {dispatchData.data.contactName}</p>
            <p>ContactNumber: {dispatchData.data.contactPhone}</p>
            <p>Country: {dispatchData.data.contactCountry}</p>
            <p>ZipCode: {dispatchData.data.contactZipCode}</p>
            <p>Address: {dispatchData.data.contactAddress}</p>
            <p>TimePeriod: {dispatchData.data.contactTime}</p>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
        <TextField
            sx={{ mt: 2 }}
            fullWidth
            label="ShippingId"
            variant="outlined"
            style={{width:'60%',marginRight:'30px'}}
            value={shippingId}
            onChange={(e) => setShippingId(e.target.value)}
          />
          <Button 
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={handlePostShippingId}
          >
            Submmit
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Fade>
</Modal>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Reason for unsubscribing</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="reason"
            type="email"
            fullWidth
            variant="standard"
            value={reason}
            onChange={(e)=>setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>cancel</Button>
          <Button onClick={handlePostReason}>submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}