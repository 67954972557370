import * as React from 'react';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useParams,useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';

import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-unresolved
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';

function SidCellRenderer(props) {
  const { value } = props;

  return (
    <Grid container alignItems="center">
      <Grid item>{value}</Grid>
      <Grid item>
        <IconButton component={Link} to={`/admin/mac/ip/${value}`} size="small">
          <WifiPasswordIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

const columns = [
  { field: 'id', headerName: 'ID', width: 150, hide: true},  
  {
    field: 'ip',
    headerName: 'IP',
    width: 150,
    editable: false,
    valueGetter: (params) => params.value || '0.0.0.0',
    renderCell: SidCellRenderer,
  },
  {
    field: 'status',
    headerName: '状态',
    width: 200,
    editable: false,
    valueGetter: (params) => params.value || '',
  },
  {
    field: 'desc',
    headerName: '詳情',
    width: 400,
    editable: false,
    valueGetter: (params) => params.value || '',
  },
];


const columnVisibilityModel = {
  id:false
}



export default function Ip() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dash.surfshark-router.com/api/ip', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (response.status === 200) {
        const modifiedData = response.data.map((item, index) => {
          const modifiedItem = { ...item, id: index };
          return modifiedItem;
        });
      
        setRows(modifiedData);
      }  
    } catch (error) {
      console.error('Error fetching data: ', error);
      
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized
          navigate('/login', { replace: true });
        } else {
          // Other 4xx or 5xx errors
          alert('發生錯誤');
        }
      } else {
        // Network error or other issues
        alert('發生網路錯誤或其他問題');
      }
    }
  };
  



  useEffect(() => {
    fetchData()
  }, []); 

  return (
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                异常IP
                </Typography>
            </Grid>
      </Grid>
      <DataGridPro
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        disableColumnMenu
      />
    </Box>
  );
}