import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
/* eslint-disable-next-line import/no-unresolved */
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import { Link, useNavigate } from 'react-router-dom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { IconButton } from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import AccountSearch from '../search/AccountSearch';

function SidCellRenderer(props) {
  const { value } = props;

  return (
    <Grid container alignItems="right">
      <Grid item>{value}</Grid>
      <Grid item>
        <IconButton component={Link} to={`/admin/mac/sid/${value}`} size="small">
          <ContentPasteSearchIcon />
        </IconButton>
        <IconButton component={Link} to={`https://my.surfshark-router.com/view/${value}`} size="small">
          <ManageSearchIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'sid',
    headerName: '手机号码',
    width: 210,
    editable: false,
    renderCell: SidCellRenderer,
  },
  {
    field: 'desc',
    headerName: '備註',
    width: 100,
    editable: true,
  },
  {
    field: 'name',
    headerName: '名字',
    width: 165,
    editable: false,
    renderCell: (params) => (<span style={{ whiteSpace: "break-spaces", fontFamily: "monospace", fontSize: "0.8em" }}>{params.row.lname} {params.value}<br />{params.row.email}</span>),
  },
  {
    field: 'router_total',
    headerName: '数量',
    width: 80,
    editable: false,
  },
  {
    field: 'addr',
    headerName: '钱包地址',
    width: 230,
    editable: false,
    renderCell: (params) => (<span style={{ fontFamily: "monospace", fontSize: "0.8em" }}>{params.value}</span>),

  },
  {
    field: 'email',
    headerName: '信箱',
    width: 200,
    editable: false,
    renderCell: (params) => (<span style={{ fontFamily: "monospace", fontSize: "0.8em" }}>{params.value}</span>),
  },
  {
    field: 'avg',
    headerName: '日收益',
    width: 70,
    editable: false,
  },
  {
    field: 'amount',
    headerName: '帐额',
    width: 85,
    editable: false,
  },
  {
    field: 'last',
    headerName: '上次登入',
    width: 110,
    editable: false,
    valueFormatter: (params) => { 
      const date = new Date(params.value || new Date());
      const minutes = (`0${  date.getMinutes()}`).slice(-2); 
      const hours = (`0${  date.getHours()}`).slice(-2);     
      return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
    }, 
  },
  {
    field: 'ip',
    headerName: 'ip',
    width: 110,
    editable: false,
  },
  {
    field: 'since',
    headerName: '创立时间', 
    width: 110,
    editable: false,
    valueFormatter: (params) => { 
      const date = new Date(params.value || new Date());
      const minutes = (`0${  date.getMinutes()}`).slice(-2); 
      const hours = (`0${  date.getHours()}`).slice(-2);     
      return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
    }, 
  },
];
const columnVisibilityModel = {
  id: false,
  email: false,
}

export default function AccountList() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  const dashClient = axios.create({
    baseURL: 'https://dash.surfshark-router.com',
    headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`},
  });

  const fetchData = async () => {
    try {
      const response = await dashClient.get('/api/acct');
  
      if (response.status === 200) {
        const modifiedData = response.data.map((item) => {
          const modifiedItem = { ...item, id: item.id_acct };
  
          delete modifiedItem.id_acct;
  
          return modifiedItem;
        });
  
        setRows(modifiedData);
        setFilterRows(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  
  const processRowUpdate = (newRow, oldRow) => {
    // 透過 newRow 的 id 找到 editedRows 陣列中的索引
    const index = editedRows.findIndex(row => row.id === newRow.id);
  
    // 若找到相同的 id，則先刪除
    if (index > -1) {
      editedRows.splice(index, 1);
    }
  
    // 將 newRow 加入 editedRows 陣列
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };
  const handleSave = async () => {
    const modifiedRows = editedRows.map(item => ({
      ...item,
    }));
  
    const requests = modifiedRows.map(async (row) => {
      try {
        const response = await dashClient.post('/api/acct', {
          _id: row._id,
          desc: row.desc,
        });
        console.log('提交成功', response.data);
        fetchData();
      } catch (error) {
        console.error('提交失败', error);
        alert('提交失败');
      }
    });
    await Promise.all(requests);
    fetchData();
    setEditedRows([]);
    alert('所有提交操作已完成');
  };

  const handleRestore = async () => {
    setEditedRows([]);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []); 

  const isDisabled = editedRows.length === 0;
  return (
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    Account 
                </Typography>
            </Grid>
            <AccountSearch rows={rows} setFilterRows={setFilterRows}/>
      </Grid>
      <Button variant="outlined" disabled={isDisabled} onClick={handleSave}  startIcon={<SaveIcon />}>         
          储存
      </Button>
      <Button variant="outlined" disabled={isDisabled} onClick={handleRestore}  startIcon={<ClearIcon />}> 
          还原
      </Button>
      <DataGridPro
        rows={filterRows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'router_total', sort: 'desc' }],
          },
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error=>alert(error)}
        onRowEditCommit={(rowId, e) => {
          handleSave(rowId,e);
        }}

      />
    </Box>
  );
}