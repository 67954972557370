import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [acc,setAcc] = useState('');
  const [pw,setPw] = useState('');


  const handleLogin = async () => {
    try {
      const response = await axios.post(`https://dash.surfshark-router.com/api/auth`, { username: acc, password: pw });
      
      if (response.status === 200) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('level', response.data.level);
        sessionStorage.setItem('name', response.data.name);
        switch(response.data.level)
        {
          case 'admin':
            navigate('/admin/account', { replace: true })
            break;
          case 'staff':
            navigate('/admin/neworder', { replace: true })
            break;
          default:
            navigate('/login', { replace: true });
        }
        
      } else {
        alert('發生错误');
      }
    } catch (error) {
      console.error('Error:', error);
  
      if (error.response && error.response.status === 401) {
        alert('帐密错误');
      } else {
        alert('發生错误');
      }
    }
  };
  
  

  return (
    <>
      <Stack spacing={3}>
        <TextField name="account" label="Account" onChange={(e)=>setAcc(e.target.value)}/>

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>setPw(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" >
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} style={{marginTop:'10%'}}>
        Login
      </LoadingButton>
    </>
  );
}
