import * as React from 'react';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup, Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
// eslint-disable-next-line import/no-unresolved
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';


const columnVisibilityModel = {
  type:false
}

export default function NewOrders() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [orderId, setOrderId] = useState("");
  const [macs, setMacs] = useState('');
  const [macsCount, setMacsCount] = useState(0);
  const [model, setModel] = useState('completed');
  const [editedRows, setEditedRows] = React.useState([]);
  const [selectedRow,setSelectedRow] = useState(null);
  const [checked, setChecked] = useState(false);

  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  const GridCellExpand = React.memo((props) => {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
  
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };
  
    const handleMouseLeave = () => {
      setShowFullCell(false);
    };
  
    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }
  
      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
  
    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: 'center',
          lineHeight: '24px',
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: '100%',
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }

  const columns = [
    {
      field: 'type',
      headerName: '类型',
      width: 150,
      editable: false,     
    },
    { field: 'id', headerName: '订单编号', width: 80},
    {
      field: 'date',
      headerName: '建立/更新时间',
      width: 125,
      editable: false,
      valueGetter: (params) =>{
        const date1 = new Date(params.row.date || new Date());
        const date2 = new Date(params.row.fileModifiedTime || new Date());
        const month1 = (`0${  date1.getMonth() + 1}`).slice(-2);
        const day1 = (`0${  date1.getDate()}`).slice(-2);
        const minutes1 = (`0${  date1.getMinutes()}`).slice(-2); 
        const hours1 = (`0${  date1.getHours()}`).slice(-2);     
        const month2 = (`0${  date2.getMonth() + 1}`).slice(-2);
        const day2 = (`0${  date2.getDate()}`).slice(-2);
        const minutes2 = (`0${  date2.getMinutes()}`).slice(-2);
        const hours2 = (`0${  date2.getHours()}`).slice(-2);
        return `${month1}/${day1} ${hours1}:${minutes1},${month2}/${day2} ${hours2}:${minutes2}`;
      },
      renderCell: (params) => (
        <span style={{fontSize: "0.8em"}}>
          {params.value.split(",")[0]}<br/>{params.value.split(",")[1]}
        </span>
      ),
    },
    { 
      field: 'status', 
      headerName: '状态', 
      width: 115,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <span style={{ marginRight: 'auto', fontSize: "0.8em" }}>{params.value}</span>
          {params.value === 'processing' ? (
            <ButtonGroup variant="text" aria-label="outlined primary button group">
              <Button onClick={() => handleClickDialogOpen(params.row.id, params.row)}>出貨</Button>
            </ButtonGroup>
          ) : null}
        </div>
      ),
    },
    {
      field: 'amount',
      headerName: '數量',
      width: 60,
      editable: false,
      valueGetter: (params) => params.value || '0',
    },
    {
      field: 'status_text',
      headerName: '狀態',
      width: 155,
      editable: false,
      valueGetter: (params) => params.value || '0',
      renderCell: (params) => (<span style={{ whiteSpace: "break-spaces", fontSize: "0.8em" }}>{params.value}</span>),
    },
    {
      field: 'sid',
      headerName: '会员',
      width: 150,
      editable: false,
      valueGetter: (params) => params.value || '0',
    },
    {
      field: 'rcptName',
      headerName: '收件人/電話',
      width: 110,
      editable: false,
      resizable: false,
      renderCell: (params) => (<span style={{ whiteSpace: "break-spaces", fontSize: "0.8em" }}>{params.value} {params.row.phone}</span>),

    },
    {
      field: 'address',
      headerName: '地址',
      width: 175,
      editable: false,
      renderCell: (params) => (<span style={{ whiteSpace: "break-spaces", fontSize: "0.8em" }}>{params.row.customSelectCountry} {params.row.zipCode}<br/>{params.row.address}</span>),
    },
    { 
      field: 'macs', 
      headerName: '機號表', 
      width: 160, 
      // renderCell: renderCellExpand,
    },
  ];

  const handleClickDialogOpen = (id, row) => {
    setSelectedRow(row);
    setOrderId(id);
    setDialogOpen(true);
    if(row?.macs && row?.macs.length){
      console.log(row.macs.join('\n'));
      setMacs((row?.macs||"").join('\n'));
      setMacsCount((macs||"").split('\n').length);
    }
  };

  const handlePostMacs = async () => {
    const shortMacs = macs.trim().replaceAll(":","").replaceAll("F85E3C","").split('\n').filter((item) => item.trim().length > 0);
    for (let i = 0; i < shortMacs.length; i+=1) {
      if (shortMacs[i].length !== 6) {
        alert(`第 ${i+1} 台, 機號應為完整12碼, 例: F85E3CAABBCC`);
        return;
      }
      // check if mac contains only hex characters
      if (!/^[0-9A-Fa-f]+$/.test(shortMacs[i])) {
        alert(`第 ${i+1} 台, 機號只允許0~9與A~F, 例: F85E3CAABBCC`);
        return;
      }
    }
    try {
      const response = await axios.post('https://dash.surfshark-router.com/api/order', {
        id: orderId,
        macs: shortMacs,
        completed: checked,
      });
      alert('成功提交');
      setMacs('');
      fetchData();
      handleDialogClose();
    } catch (error) {
      console.error(error);
      alert('提交失败');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get('https://dash.surfshark-router.com/api/orders/new', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        setRows(response.data);      
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  
  const processRowUpdate = (newRow, oldRow) => {
    // 透過 newRow 的 id 找到 editedRows 陣列中的索引
    const index = editedRows.findIndex(row => row.id === newRow.id);
  
    // 若找到相同的 id，則先刪除
    if (index > -1) {
      editedRows.splice(index, 1);
    }
  
    // 將 newRow 加入 editedRows 陣列
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };
  const handleSave = async () => {
    const modifiedRows = editedRows.map(item => ({
      ...item,
    }));
  
    const requests = modifiedRows.map(async (row) => {
      try {
        const response = await axios.post('https://dash.surfshark-router.com/api/order', {
          id: row.id,
          desc: row.desc
        });
        console.log('提交成功', response.data);
        fetchData();
      } catch (error) {
        console.error('提交失败', error);
        alert('提交失败');
      }
    });
    await Promise.all(requests);
    fetchData();
    setEditedRows([]);
    alert('所有提交操作已完成');
  };
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleRestore = async () => {
    setEditedRows([]);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, []); 
  const isDisabled = editedRows.length === 0;
  const dialogPrompts = {
    "ship": {title: '出貨機號清單', prompt: '請輸入機號(一台機器一行)'}, 
  };
  const textFieldRef = useRef();
  const handleMacsChange = (event) => {
    const input = textFieldRef.current;
    const previousCursorPosition = input.selectionStart;
  
    setMacs(event.target.value.toUpperCase());
    setMacsCount(event.target.value.split('\n').length);
  
    // 使用 setTimeout 確保游標位置更新在 React 狀態更新之後
    setTimeout(() => {
      input.setSelectionRange(previousCursorPosition, previousCursorPosition);
    }, 0);
  };
  return (
    <Box sx={{ height: 600, width: '90%', margin:'auto'}}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">          
                   申購訂單 
                </Typography>
            </Grid>
      </Grid>
      <Button variant="outlined" disabled={isDisabled} onClick={handleSave}  startIcon={<SaveIcon />}>         
          储存
      </Button>
      <Button variant="outlined" disabled={isDisabled} onClick={handleRestore}  startIcon={<ClearIcon />}> 
          还原
      </Button>
      <DataGridPro
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        disableColumnMenu
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error=>alert(error)}
        onRowEditCommit={(rowId, e) => {
          handleSave(rowId,e);
        }}
      />
      <Dialog maxWidth="xs" open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{dialogPrompts.ship.title}</DialogTitle>
        <DialogContent>
          <style>{'textarea { font-family: monospace !important' }</style>
          <TextField
            inputRef={textFieldRef}
            autoFocus
            margin="dense"
            id="name"
            label={dialogPrompts.ship.prompt}
            type="email"
            fullWidth
            variant="standard"
            value={macs}
            multiline
            minRows={10}
            onChange={handleMacsChange}
          />
        </DialogContent>
        <DialogActions>
          &nbsp;&nbsp;機號總數: <span>{macsCount}</span> 台
          <span style={{flexGrow: 4}}> </span>
          <FormGroup>
            <FormControlLabel control={<Checkbox 
              checked={checked}
              onChange={handleCheckboxChange} 
              />} label="完成申購單" />
          </FormGroup>
          <Button onClick={handleDialogClose}>取消</Button>
          <Button onClick={handlePostMacs}>送出</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}