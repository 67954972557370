import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import AccountList from './erp/AccountList';
import Mac from './erp/Mac';
import NewOrders from './erp/NewOrders';
import ReturnOrders from './erp/ReturnOrders';
import WithdrawOrders from './erp/WithdrawOrders';
import Ip from './erp/Ip';
import Rate from './erp/Rate';
import BanList from './erp/BanList';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/account" />, index: true },
        { path: 'account', element: <AccountList /> },
        { path: 'mac', element: <Mac /> },
        { path: 'mac/sid/:sid', element: <Mac /> }, 
        { path: 'mac/ip/:ip', element: <Mac /> }, 
        { path: 'mac/ban', element: <Mac /> }, 
        { path: 'neworder', element: <NewOrders /> },
        { path: 'returnorder', element: <ReturnOrders /> },
        { path: 'withdraworder', element: <WithdrawOrders /> },
        { path: 'ip', element: <Ip /> },
        { path: 'rate', element: <Rate /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
