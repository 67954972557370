import * as React from 'react';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
// eslint-disable-next-line import/no-unresolved
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';

const columnVisibilityModel = {
  type:false
}

export default function Rate() {

  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [hash,setHash]= useState('');
  const [model,setModel]= useState('');
  const [editedRows, setEditedRows] = React.useState([]);
  const [selectedRow,setSelectedRow]= useState(null);

  const columns = [
    {
      field: 'type',
      headerName: '类型',
      width: 150,
      editable: false,
    },
    { field: 'id', headerName: '憑證總類', width: 120},
    { field: 'rate', headerName: '利率/分鐘', width: 120, editable: true},
    { field: 'dailyRate', headerName: 'USDT/日', width: 120, editable: false},
    {
      field: 'desc',
      headerName: '描述',
      width: 300,
      editable: false,
      valueGetter: (params) => params.value || '',
    }
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get('https://dash.surfshark-router.com/api/rates', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
  
      if (response.status === 200) {
        setRows(response.data);      
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  
  const processRowUpdate = (newRow, oldRow) => {
    // 透過 newRow 的 id 找到 editedRows 陣列中的索引
    const index = editedRows.findIndex(row => row.id === newRow.id);
  
    // 若找到相同的 id，則先刪除
    if (index > -1) {
      editedRows.splice(index, 1);
    }
  
    // 將 newRow 加入 editedRows 陣列
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };
  const handleSave = async () => {
    const modifiedRows = editedRows.map(item => ({
      ...item,
    }));
  
    const requests = modifiedRows.map(async (row) => {
      try {
        const response = await axios.post('https://dash.surfshark-router.com/api/rate', {
          id: row.id,
          rate: row.rate,
          desc: row.desc
        });
        console.log('提交成功', response.data);
        fetchData();
      } catch (error) {
        console.error('提交失败', error);
        alert('提交失败');
      }
    });
    await Promise.all(requests);
    fetchData();
    setEditedRows([]);
    alert('所有提交操作已完成');
  };

  const handleRestore = async () => {
    setEditedRows([]);
    fetchData();
  };

  useEffect(() => {
    fetchData()
  }, []); 
  const isDisabled = editedRows.length === 0;
  return (
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">          
                   利率 
                </Typography>
            </Grid>
      </Grid>
      <p>修改利率以變更每日收益</p>
      <Button variant="outlined" disabled={isDisabled} onClick={handleSave}  startIcon={<SaveIcon />}>         
          储存
      </Button>
      <Button variant="outlined" disabled={isDisabled} onClick={handleRestore}  startIcon={<ClearIcon />}> 
          还原
      </Button>
      <DataGridPro
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
        disableColumnMenu
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error=>alert(error)}
        onRowEditCommit={(rowId, e) => {
          handleSave(rowId,e);
        }}
      />
    </Box>
  );
}