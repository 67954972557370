// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: '帐号',
    path: '/admin/account',
    icon: icon('ic_analytics'),
  },
  {
    title: '机器',
    path: '/admin/mac',
    icon: icon('ic_analytics'),
  },
  {
    title: '申购',
    path: '/admin/neworder',
    icon: icon('ic_analytics'),
  },
  {
    title: '退购',
    path: '/admin/returnorder',
    icon: icon('ic_analytics'),
  },
  {
    title: '提领',
    path: '/admin/withdraworder',
    icon: icon('ic_analytics'),
  },
  {
    title: '黑名单',
    path: '/admin/mac/ban',
    icon: icon('ic_analytics'),
  },
  {
    title: '异常IP',
    path: '/admin/ip',
    icon: icon('ic_analytics'),
  },
  {
    title: '利率',
    path: '/admin/rate',
    icon: icon('ic_analytics'),
  },
];

export default navConfig;
